.blinking-svg {
  animation: blink 1s infinite;
  fill: red;
}

@keyframes blink {
  0%,
  100% {
    fill: rgba(166, 0, 0, 0.642);
    transform: scale(1);
  }
  50% {
    fill: rgb(0, 0, 0);
    transform: scale(1.5);
  }
}
