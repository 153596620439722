.background {
  z-index: -1;
  position: fixed;
  object-fit: fill;
  width: 100%;
  height: 100vh;
}

@media (max-width: 600px) {
  .background {
    width: 100vw;
    height: 100vh;
  }
}
