.title {
  margin-bottom: 15px;
}

.subtitle {
  margin-bottom: 15px;
  font-size: 20px;
  border-bottom: 1px solid black;
}

.edit-menu1 {
  width: 85%;
  box-shadow: gray 0 0 7px 3px;
  padding: 20px;
  margin-bottom: 20px;
}

.buttons-cnt {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  width: 100%;
  height: 40px;
}

.add-meal {
  background-color: #00ec00;
  width: 120px;
  align-self: center;
  border-radius: 20px;
  text-align: center;
}

.edit-accept {
  background-color: #007eff;
  width: 120px;
  align-self: center;
}

.delete-svg {
  color: red;
  font-size: 20px;
}

.eye-svg {
  color: rgb(57, 57, 57);
  font-size: 20px;
}

.btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: 25px;
  justify-content: center;
  padding: 0;
}
