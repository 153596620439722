/* Main Background Style */
.restaurant-bg {
  width: 2000px;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background: linear-gradient(0deg, transparent 98%, gray 100%),
    linear-gradient(90deg, transparent 98%, gray 100%),
    /* Inner small squares */
      linear-gradient(
        0deg,
        rgb(211, 211, 211) 5%,
        transparent 6%,
        transparent 100%
      ),
    linear-gradient(
      90deg,
      rgb(209, 209, 209) 5%,
      transparent 6%,
      transparent 100%
    );

  background-size: 
  /* Big squares */ 100px 100px, 100px 100px,
    /* Small squares */ 10px 10px, 10px 10px;
  background-color: #f4f4f4; /* Light background */
  position: relative;
  height: 1200px;
}

.table-cnt {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  overflow: hidden;
  position: absolute;
}

.table-svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  /* transform: rotate(45deg); */
}

.table-circle {
  position: absolute;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(93 93 93);
  color: #fafafa;
}
