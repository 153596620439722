.menu3-cnt {
  display: flex;
  flex-direction: column;
}

.menu3-category {
  color: white;
  text-shadow: 0 0 4px black;
  font-size: 45px;
  margin: 0;
  margin-bottom: 15px;
  text-align: center;
  align-self: center;
}

.menu3-card {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.menu3-item-img {
  height: 120px;
  width: 75px;
  object-fit: cover;
}
.menu3-item-name {
  color: white;
  font-weight: 700;
  font-size: 20px;
}

.menu3-item-price {
  color: white;
  align-self: center;
  font-size: 19px;
  font-weight: bold;
  width: 40px;
}

.menu3-item-desc {
  color: white;
  font-size: 15px;
  width: 120px;
}

.menu3-title-cnt {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 180px;
}
