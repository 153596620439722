* {
  direction: rtl;
  margin: 0;
  overflow: auto;
}

body {
  min-height: 100vh;
  overflow: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  overflow: hidden;
}

button {
  cursor: pointer;
  border-radius: 7px;
  background-color: black;
  color: white;
  font-weight: 600;
  border: none;
}

input {
  border-color: black;
}

.card {
  height: 260px;
  width: 290px;
  box-shadow: 0 0 15px rgb(72, 72, 72);
  border-radius: 40px;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

input {
  text-align: center;
}

textarea {
  width: 190px;
  height: 85px;
  resize: none;
}

h6 {
  margin: 0;
  margin-top: 10px;
}

.gradient-text {
  background: linear-gradient(to right, rgb(70, 183, 228), rgb(199, 10, 199));
  -webkit-background-clip: text;
  color: transparent;
}
