.alma-phone-cnt {
  display: flex;
  height: 100vh;
  background-color: #adc4d8;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.alma-phone-cnt input {
  direction: ltr;
}
