.dashboard-container {
  font-family: "Arial", sans-serif;
  color: #333;
  direction: rtl;
  padding: 20px;
}

.dashboard-header h1 {
  text-align: center;
  font-size: 36px;
}

.dashboard-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 30px;
}

.dashboard-section {
  width: 90%;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #f9f9f9;
}

.dashboard-section h2 {
  text-align: right;
  font-size: 24px;
  margin-bottom: 15px;
}

.table-container {
  overflow-x: auto;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  text-align: right;
}

.orders-table th,
.orders-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.navigate-orders-btn {
  margin-top: 20px;
  display: block;
  width: 100%;
}

.order-detail-btn {
  background-color: #008cba;
}

.order-detail-btn:hover {
  background-color: #007bb5;
}

.graph-container {
  width: 100%;
  margin-top: 20px;
}

.graph {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  height: 250px;
  background-color: #e0e0e0;
  padding: 0 20px;
  border-radius: 5px;
}

.graph-bar {
  width: 30px;
  background-color: #4caf50;
  border-radius: 3px;
  position: relative;
  display: flex;
  justify-content: center;
}

.graph-bar-label {
  position: absolute;
  bottom: -20px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #333;
}

.graph-bar:hover {
  background-color: #45a049;
}
