.title {
  margin-bottom: 15px;
  font-size: 20px;
  border-bottom: 1px solid black;
}

.delete-meal-btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: 25px;
  justify-content: center;
  padding: 0;
  margin: 0;
}
.delete-meal-svg {
  color: red;
  font-size: 20px;
}
