.home-cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
}

.home-background {
  z-index: -1;
  position: fixed;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.home-logo {
  background-color: black;
  box-shadow: 0 0 20px #b2b2b2;
  margin-top: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0px;
  justify-content: center;
  margin: 20px;
}

.hero-cnt {
  display: flex;
  height: 75vh;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.hero-bg {
  background: linear-gradient(
    -45deg,
    rgba(238, 118, 82, 0.5),
    rgba(231, 60, 126, 0.5),
    rgba(35, 165, 213, 0.5),
    rgba(35, 213, 171, 0.5)
  );
  background-size: 400% 400%;
  animation: gradient 7s ease-in-out infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.contact-btn {
  margin: 30px 0;
  width: 150px;
  height: 40px;
  color: black;
  background-color: white;
  box-shadow: white 0 0 5px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.cards-cnt {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.home-crd {
  justify-content: center;
  align-self: center;
  text-align: center;
  width: 150px;
  height: 150px;
  color: black;
  background-color: whitesmoke;
}

.menus-cnt {
  display: flex;
  width: 100%;
  height: 360px;
  padding: 50px 0;
  align-content: center;
  margin-top: 20px;
  justify-content: space-evenly;
  overflow: hidden;
}

.home-menu {
  width: 200px;
  height: 260px;
  background-color: wheat;
  animation: moveUpDown 2s ease-in-out infinite alternate;
}

@keyframes moveUpDown {
  0% {
    margin-top: 0;
  }
  100% {
    margin-top: 30px;
  }
}

@keyframes moveUpDown2 {
  0% {
    margin-top: 40px;
  }
  100% {
    margin-top: 10px;
  }
}

@keyframes bigFont {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1.5);
  }
}

.menu1 {
  z-index: 1;
  background-color: black;
  box-shadow: 0 0 20px 3px #000000;
  background-size: cover;
}

.menu2 {
  left: 0;
  z-index: 2;
  background-size: cover;
  box-shadow: 0 0 10px 3px #e0ddd1;
}

.menu3 {
  z-index: 3;
  background-size: cover;
  margin-top: 30px;
  box-shadow: 0 0 20px 3px #582304;
  opacity: 95%;
  animation: moveUpDown2 2s ease-in infinite alternate;
}

.menu-story-cnt {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  width: 100%;
  background-color: #1a1a1a;
}

.pulset {
  animation: pulse 2s ease-in-out infinite alternate;
}

@keyframes pulse {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.3);
  }
}

.menu-contact-cnt {
  margin-top: 70px;
  display: flex;
  width: 90%;
  color: wheat;
  justify-content: center;
  text-align: center;
  height: 333px;
  justify-content: space-around;
}

.contact-title {
  background-image: linear-gradient(
    to bottom,
    rgba(37, 37, 37, 1) 0%,
    rgba(37, 37, 37, 0.9) 75%,
    rgba(37, 37, 37, 0) 100%
  );
  display: flex;
  flex-direction: column;
  padding: 60px 20px;
  width: 600px;
}

.contact-home-cnt {
  color: rgb(255, 239, 211);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
  text-align: center;
  width: 688px;
  background-image: linear-gradient(
    to bottom,
    rgba(37, 37, 37, 1) 0%,
    rgba(37, 37, 37, 0.9) 75%,
    rgba(37, 37, 37, 0) 100%
  );
  padding: 30px;
  box-shadow: #f5f5f52e 0px -10px 7px 7px;
  border-radius: 15px 15px 0 0;
}

.contact-home-cnt span {
  font-weight: bolder;
}

.contact-home-cnt form {
  display: flex;
  flex-direction: column;
  height: 170px;
  justify-content: space-between;
  margin-top: 25px;
}

.contact-home-cnt h2 {
  font-size: 25px;
}

.wa-btn {
  padding: 10px;
  width: 220px;
  position: fixed;
  bottom: 35px;
  z-index: 10;
  background-image: url("../uploads/WA.png");
  height: 50px;
  width: 50px;
  background-size: contain;
  right: 25px;
  border-radius: 50px;
  box-shadow: green 0 0 7px 2px;
}

.manage-cnt {
  display: flex;
  padding: 0 60px;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(37, 37, 37, 1) 0%,
    rgba(37, 37, 37, 0.9) 75%,
    rgba(37, 37, 37, 0) 100%
  );
  justify-content: space-around;
}

.manage-title {
  color: white;
  font-size: 45px;
  text-align: center;
  margin-top: 100px;
}

.content-cnt {
  display: flex;
  flex-direction: column;
  color: white;
  align-self: center;
  text-align: center;
  align-items: center;
}

.solve {
  font-size: 20px;
  margin-top: 7px;
}

.ad-space {
  margin: 30px 0;
  width: 290px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 11px 2px;
  background-color: #00000040;
}

.content-options {
  margin-top: 20px;
  color: rgb(32, 32, 32);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  background-image: linear-gradient(
    to bottom,
    rgba(37, 37, 37, 0.1) 0%,
    rgba(37, 37, 37, 0.9) 15%,
    rgba(37, 37, 37, 1) 100%
  );
}

.option {
  width: 100%;
  text-align: right;
  font-size: 18px;
}

.content-2 {
  font-size: 20px;
}

.option-cnt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 5px;
}

@media screen and (max-width: 600px) {
  .menu1 {
    position: initial;
  }

  .menu2 {
    position: initial;
  }

  .manage-cnt {
    flex-direction: column;
    width: 100%;
    padding: 0 20px;
  }

  .hero-cnt {
    justify-content: space-evenly;
    flex-direction: column-reverse;
  }

  .menu-contact-cnt {
    flex-direction: column;
    height: auto;
    width: 95%;
    align-items: center;
  }

  .contact-title {
    width: auto;
  }

  .content-options {
    width: 100%;
  }

  .contact-home-cnt {
    width: 90%;
  }
}
