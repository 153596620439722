/* Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

/* Authentication form container */
.user-auth-container {
  background-color: #404040;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 75%;
  max-width: 400px;
  text-align: center;
  color: wheat;
}

/* Form title */
.auth-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

/* Input fields group */
.auth-input-group {
  margin: 0px;
  text-align: right;
  font-weight: bold;
}

/* Input fields */
.auth-input-group input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  direction: rtl; /* Right-to-left for Hebrew input */
}

.user-auth-accept {
  width: 150px;
  height: 35px;
  margin-top: 30px;
  transition: 1s;
}

.user-auth-accept:hover {
  background-color: wheat;
  color: black;
}
