.hostess-cnt {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: rgb(15, 15, 15);
  align-items: center;
}

.hostess-table {
  height: 100px;
  width: 115px;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-radius: 15px;
}

.table-status-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  width: 85%;
  margin-top: 20px;
  color: wheat;
}

.table-status {
  display: flex;
  align-items: center;
}

.status-box {
  width: 20px;
  height: 20px;
  margin-left: 3px;
  border-radius: 3px;
}

.available {
  background-color: #00e50a; /* צבע ירוק לפנוי */
}

.occupied {
  background-color: #ff2b1b; /* צבע אדום לתפוס */
}

.reserved {
  background-color: #ffd700; /* צבע צהוב לשמור */
}

.darkmode-btn {
  width: 50px;
  height: 50px;
  margin: 30px;
  background: #333;
  border-radius: 100%;
  color: white;
  padding: 7px;
}

.lightmode-btn {
  width: 50px;
  height: 50px;
  margin: 30px;
  background: #333;
  border-radius: 100%;
  color: yellow;
  padding: 7px;
}
