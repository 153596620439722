.menu1-cnt {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 320px;
}

.menu1-item-cnt {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.menu1-category {
  color: white;
  text-shadow: 0 0 4px black;
  font-size: 40px;
  margin: 0;
  align-self: center;
  text-align: center;
}

.menu1-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu1-item-title {
  color: white;
  font-size: 18px;
  font-weight: 700;
}

.menu1-price {
  font-size: 15px;
  width: 70px;
  text-align: center;
  font-weight: bold;
  align-self: center;
}

.menu1-desc {
  color: white;
  font-weight: 400;
  margin-top: 8px;
  font-size: 14px;
  width: 250px;
}

.menu1-note {
  font-size: small;
  font-weight: 400;
  color: #e9e9e9c2;
  max-width: 250px;
}
