/* Checkout.css */
.checkout-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 30px 15px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  box-shadow: black 0 0 10px;
  margin: 80px 25px 25px 25px;
}

.checkout-title {
  text-align: center;
  font-size: 2.5em;
  margin-top: 10px;
  color: #333;
  border-bottom: black solid 1px;
}

.checkout-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input-group {
  margin-top: 40px;
}

.input-group label {
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.input-group input,
.input-group textarea {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.payment-method {
  margin: 20px 0;
}

.payment-options {
  display: flex;
  flex-direction: column;
}

.payment-options label {
  margin: 8px 0;
  color: #555;
}

.order-summary {
  margin-top: 30px;
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.order-summary ul {
  padding-left: 0;
}

.summary-table {
  width: 100%;
  border-collapse: collapse;
}

.summary-table th,
.summary-table td {
  padding: 12px;
  text-align: right;
  font-size: 16px;
  border-bottom: 1px solid #ddd;
}

.summary-table th {
  background: #eaeaea;
  font-weight: 600;
}

.summary-table tfoot td {
  font-weight: bold;
  font-size: 18px;
  color: #333;
}

.checkout-button {
  margin-top: "20px";
  background-color: #4caf50;
  color: white;
  padding: 16px;
  font-size: 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s, background-color 0.3s;
  width: 100%;
  box-shadow: none;
  margin-top: 30px;
}

.checkout-button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}
