.meals-cnt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.meal {
  width: 360px;
  min-height: 120px;
  box-shadow: 0 0 5px white;
  margin: 5px 0px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  color: wheat;
}

.title-price {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  height: auto;
}

.title-price p {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
}

.meals-img {
  width: 80px;
  height: 80px;
  align-self: center;
  object-fit: cover;
  border-radius: 10px;
}

.desc {
  font-size: 12px;
  white-space: pre-line;
}

.edit-cnt {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 20px;
}
.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 1000;
  overflow: hidden; /* Prevents overflow of content */
}

.modal-meals {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden; /* Prevents overflow of content */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal-content {
  background: white;
  padding: 20px;
  width: 100%;
  max-width: 400px;
  border-radius: 8px 8px 0 0;
  z-index: 1001;
  text-align: center;
  max-height: 90vh; /* Limit height for scrolling */
  overflow-y: auto; /* Enables scrolling */
  min-height: 70%;
}

.modal-img {
  width: 100%;
  height: 100%;
  border-radius: 8px 8px 0 0;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  text-align: right;
}

.modal-body {
  text-align: center;
  padding: 10px;
}

.close-btn {
  cursor: pointer;
  background: none;
  border: none;
  font-size: 25px;
  font-weight: bold;
  color: red;
  border-radius: 25px;
}

.quantity-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.quantity-btn {
  background-color: black;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.quantity-btn:hover {
  background-color: grey;
}

.add-to-cart-btn {
  background-color: black;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;
  border-radius: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.add-to-cart-btn p {
  margin: 0;
}

.add-to-cart-btn:hover {
  background-color: grey;
}

.menu-cart-cnt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
}

.quantity-controls {
  display: flex;
  height: 50px;
  width: 120px;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
  margin-right: 15px;
}

.quantity-controls input {
  width: 40px;
  border: none;
  font-weight: bold;
  border-radius: 5px;
  background-color: #444;
  color: wheat;
  height: 30px;
  padding: 0;
  margin-bottom: 0;
}

.quantity-controls svg {
  font-size: 30px;
}

.modal-meals-options-cnt {
  display: flex;
  flex-direction: column;
}

.modal-meals-options {
  margin: 15px 0;
  text-align: right;
}

.modal-meals-option-choice {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}

.modal-meals-option-choice input {
  width: 20px;
  margin: 0;
  margin-left: 15px;
}
