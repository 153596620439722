.menu-cnt {
  min-height: 100vh;
  background-color: black;
  display: flex;
  flex-direction: column;
}

.menu-logo {
  align-self: center;
}

.menu-btn-cnt2 {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  top: 20px;
  padding-bottom: 15px;
}

.home-btn {
  margin-top: 80px;
  position: absolute;
  width: 30px;
  height: 30px;
  background: linear-gradient(
    135deg,
    rgb(194, 161, 95),
    rgb(212, 175, 55),
    rgb(245, 221, 121),
    rgb(212, 169, 82),
    rgb(229, 203, 103)
  );
  padding: 5px;
  border-radius: 15px 0px 0px 15px;
}

.manager-btn {
  left: 0;
  margin-top: 80px;
  position: absolute;
  width: 30px;
  height: 30px;
  background: linear-gradient(
    135deg,
    rgb(194, 161, 95),
    rgb(212, 175, 55),
    rgb(245, 221, 121),
    rgb(212, 169, 82),
    rgb(229, 203, 103)
  );
  padding: 5px;
  border-radius: 0px 15px 15px 0px;
}

.menu-btn {
  width: 120px;
  height: 50px;
  background: linear-gradient(
    135deg,
    #c2a15f,
    #d4af37,
    #f5dd79,
    #d4a952,
    #e5cb67
  );
  color: black;
  font-weight: 700;
  flex: 0 0 auto;
  margin: 0 10px;
  text-wrap: wrap;
}

.menu-btn-child {
  width: auto;
  height: 30px;
  color: wheat;
  font-weight: 700;
  flex: 0 0 auto;
  text-wrap: wrap;
  text-align: start;
  margin-left: 25px;
  border-bottom: 1px wheat solid;
}

.meals-cnt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.meal {
  width: 360px;
  min-height: 120px;
  background-color: rgb(96 96 96 / 50%);
  box-shadow: 0 0 5px white;
  margin: 5px 0px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  color: wheat;
}

.title-price {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  height: auto;
}

.title-price p {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
}

.meals-cnt img {
  align-self: center;
  object-fit: cover;
  border-radius: 10px;
}

.desc {
  font-size: 12px;
}

.edit-cnt {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
  width: 30px;
}

.menu-bottom-navigate {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  z-index: 5;
  background-color: black;
  height: 70px;
  box-shadow: wheat 0 0 5px 3px;
  border-radius: 20px 20px 0 0;
}
