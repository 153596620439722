.menu-button {
  height: 200px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    135deg,
    #856d3d,
    #d4af37,
    #d7c36f,
    #d0a142,
    #d7c36f
  );
}

.menu-button img {
  border-radius: 10px;
  filter: brightness(50%);
}

.menu-title {
  position: absolute;
  font-size: 35px;
}
