.waiter-modal-orders {
  margin: 10px 0;
  background-color: black;
  padding: 15px;
  border-radius: 10px;
}
.waiter-modal-orders {
  margin-bottom: 20px;
  padding: 15px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.order-details {
  margin-left: 20px;
  margin-bottom: 20px;
  border-bottom: wheat 1px solid;
  display: flex;
}

.order-details h5 {
  margin: 0;
  font-size: 18px;
}

.order-details p {
  margin: 5px 0;
}

.order-details ul {
  margin: 5px 0 0 20px;
  padding: 0;
  list-style-type: disc;
}
