/* MenuProManager.css */

.menu-manager-cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-container {
  display: flex;
  justify-content: space-around;
  width: 300px;
}

.parent-category-menu {
  background-color: black;
  border-radius: 10px;
  color: wheat;
  font-weight: 500;
  padding: 5px;
  box-shadow: wheat 0 0 5px;
  border: none;
  width: 200px;
}

.meals-manager-cnt {
  width: 250px;
  text-align: center;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-self: center;
  height: 55px;
  padding: 10px;
}

.input-style {
  text-align: right;
  background-color: black;
  color: wheat;
  border: none;
  border-bottom: wheat solid 1px;
}
.edit-buttons-ctn {
  display: flex;
  margin-right: 15px;
  justify-content: space-between;
  width: 100px;
}

.meals-cnt-edit-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}
