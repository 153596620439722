.navigate-btn-cnt {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 10px 0 10px;
}

.cart-button {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 0; /* Makes sure overlay is on top */
}

.cart-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
  padding: 20px;
  box-shadow: 0 -4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  transform: translateY(100%); /* Initially off-screen */
  animation: slideUp 0.5s forwards; /* Slide up effect */
  z-index: 11;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.cart-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.cart-items {
  margin: 10px 0;
  max-height: 200px; /* Adjust based on your design */
  overflow-y: auto; /* Allows scrolling for long carts */
}

.cart-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.cart-item p {
  margin: 0;
}

.cart-total {
  margin: 15px 0;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

.cart-order-button {
  width: 100%;
  padding: 15px;
  background-color: #28a745; /* Green color */
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cart-order-button:hover {
  background-color: #218838; /* Darker green on hover */
}

.cart-order-button:active {
  background-color: #1e7e34; /* Even darker green on click */
}

.cart-table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.cart-table th,
.cart-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center; /* Center align text */
}

.cart-table th {
  background-color: #f2f2f2;
}

.cart-total {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.red-dot {
  background: red;
  width: 10px;
  height: 10px;
  position: relative;
  top: 0px;
  border-radius: 100%;
}
