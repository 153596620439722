.alma-cnt {
  background-color: #adc4d8;
  display: flex;
  height: 100vh;
  padding: 30px;
  flex-direction: column;
  justify-content: space-evenly;
}

.alma-cnt button {
  max-width: 350px;
}

.title-alma {
  font-size: 35px;
}

.alma-cnt img {
  height: 210px;
  box-shadow: #ffffff 0 0 13px;
}

.image-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Keep images in a single line */
  display: flex; /* Display images in a row */
}

.image-container img {
  flex: 0 0 auto; /* Prevent images from stretching */
  margin-left: 20px;
}
