.menu-btn-cnt::-webkit-scrollbar {
  width: 8px; /* Set the width of the scrollbar */
}

.menu-btn-cnt::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the color of the thumb (fill) */
}

.menu-btn-cnt::-webkit-scrollbar-track {
  background-color: transparent; /* Set the color of the track (empty space) */
}

.menu-btn-cnt button {
  flex: 0 0 120px; /* Set a fixed width for each button */
  background-color: #2e2e2e;
}
