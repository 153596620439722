.sidenav-cnt {
  position: absolute;
  z-index: 10;
  display: flex;
  top: 0;
  left: 0;
  background-color: rgb(28, 28, 28);
  width: 250px;
  height: 100%;
  color: white;
  align-items: center;
}

.sidenav-container {
  position: relative;
}

.sidenav-cnt {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #333;
  color: white;
  overflow-x: hidden;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.toggle-icon {
  position: fixed;
  top: 20px;
  left: 20px;
  cursor: pointer;
  color: #ffffff;
  font-size: 24px;
  background: #333;
  width: 60px;
  height: 60px;
  padding: 15px;
  border-radius: 100%;
  box-shadow: #333 -3px 4px 13px;
  z-index: 10;
}

.close-btn {
  cursor: pointer;
  align-self: flex-end;
  font-size: 18px;
  margin: 15px 0 0 15px;
}

.logout {
  position: absolute;
  width: 85%;
  bottom: 10px;
  background: red;
}

.alert-cnt {
  width: 100%;
  height: 80%;
  margin-top: 10px;
}

.alert {
  display: flex;
  flex-direction: column;
  background-color: black;
  width: 100%;
  padding: 10px;
  align-items: center;
  margin-top: 10px;
}
.alert-cnt button {
  background-color: none;
  border-bottom: solid 1px white;
}
