.expert-card {
  display: flex;
  height: 175px;
  width: 140px;
  margin: 15px 0;
  box-shadow: 0 0 7px #fffdef;
  border-radius: 40px;
  background-color: #fffdef;
  border: transparent;
  transition: 0.75s;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.big-expert-card {
  display: flex;
  flex-direction: column;
  height: 130px;
  margin: 15px;
  width: 300px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.95);
  border-radius: 40px;
  background-color: #fffdef;
  border: transparent;
  transition: 0.75s;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.card-title {
  text-align: center;
  width: 120px;
}

.expert-img {
  width: 80px;
  filter: brightness(0.5);
}

@media screen and (min-width: 600px) {
  .expert-card {
    height: 150px;
    width: 150px;
    margin: 40px;
  }

  .expert-card:hover {
    transition: 0.75s;
    height: 160px;
    width: 160px;
  }

  .big-expert-card:hover {
    transition: 0.75s;
    height: 140px;
    width: 310px;
  }
}
