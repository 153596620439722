.payment-method-cnt {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.payment-method {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 7px;
  height: 50px;
  object-fit: contain;
  border-bottom: black solid 1px;
}

.payment-method img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 7px;
}

.payment-method.focused {
  border: 2px solid #007aff; /* Highlighted border */
  border-radius: 8px; /* Optional: Rounded corners */
}
