.home-rest-cnt {
  background-color: black;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.buttons-cnt {
  display: flex;
  height: auto;
  justify-content: space-around;
}

.roulette-container {
  position: relative;
  width: 100%;
  max-width: 550px;
  aspect-ratio: 1;
  margin: 0 auto;
}

.roulette-container > div {
  width: 100%;
  height: 100%;
  max-width: unset;
  max-height: unset;
  overflow: hidden;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

/* Style the divider */
.divider {
  margin: 30px 0;
  height: 2px;
  width: 200px;
  background: linear-gradient(
    135deg,
    #856d3d,
    #d4af37,
    #d7c36f,
    #d0a142,
    #d7c36f
  );
  background-size: 400% 400%; /* Extend the background to cover the animation */
  animation: gradientAnimation 10s ease infinite; /* Apply the animation */
}
/* Modal */
.modal-home {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.modal-content-home {
  background: black;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 500px;
  margin: 50px 0;
  position: relative;
  color: wheat;
  box-shadow: wheat 0 0 5px;
  animation: scrollUp 0.5s forwards;
}

.close-home {
  position: absolute;
  top: -10px;
  right: 0px;
  font-size: 2rem;
  color: black;
  cursor: pointer;
}

.modal-form-home {
  display: flex;
  flex-direction: column;
}

.modal-form-home label {
  margin: 10px 5px 5px;
  font-weight: 600;
}

.modal-form-home input,
select {
  padding-right: 10px;
  border-radius: 15px;
  border: none;
  background-color: white;
  height: 35px;
  text-align: right;
  color: black;
}

.modal-form-home button {
  padding: 10px;
  font-size: 1rem;
  color: black;
  font-weight: 700;
  background: linear-gradient(
    135deg,
    #856d3d,
    #d4af37,
    #d7c36f,
    #d0a142,
    #d7c36f
  );
  border: none;
  cursor: pointer;
  margin-top: 20px;
}

/* Animations */
@keyframes scrollUp {
  from {
    top: 100%;
  }
  to {
    top: 0;
  }
}

@keyframes contentScrollDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes scrollDown {
  from {
    top: 0;
  }
  to {
    top: 100%;
  }
}

@keyframes contentScrollUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}
