/* RolesManager.css */

.roles-manager {
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #4b4b4b;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding-bottom: 30px;
  margin-bottom: 30px;
}

.roles-manager h1 {
  font-size: 24px;
  color: wheat;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
  text-align: right;
}

.form-group label {
  display: block;
  font-size: 16px;
  color: wheat;
  margin-bottom: 5px;
}

/* Dark input styling */
.dark-input {
  width: 100%;
  font-size: 16px;
  border: 1px solid #555;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  padding: 10px;
}

.dark-input:focus {
  border-color: #007bff;
  outline: none;
}

.add-role-button {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-role-button:hover {
  background-color: #0056b3;
}

/* Table styling */
.workers-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
  text-align: center;
}

.workers-table th,
.workers-table td {
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #333;
  color: #fff;
}

.workers-table th {
  background-color: #555;
  color: wheat;
}

.roles-manager {
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #4b4b4b;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.table-container {
  overflow-x: auto;
  margin-top: 20px;
}

.workers-table {
  width: 100%;
  min-width: 800px;
  border-collapse: collapse;
}

.workers-table th,
.workers-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.dark-input {
  background-color: #333;
  color: #fff;
  border: 1px solid #444;
}

.add-role-button {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-role-button:hover {
  background-color: #0056b3;
}
