.edit-category-container {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  width: 90%;
  align-items: center;
}

.category-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  width: 90%;
}

.category-title {
  text-align: center;
}

.edit-buttons-cnt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.edit-buttons-cnt button {
  cursor: pointer;
  padding: 8px 16px;
}

.edit-input {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
}

.edit-input input {
  background-color: #000; /* Black background */
  color: #fff; /* White text for contrast */
  border: 2px solid gold; /* Gold border */
  border-radius: 10px; /* Slightly rounded corners */
  padding: 10px 15px;
  outline: none;
  margin-top: 10px;
  font-size: 1rem;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.edit-input input:focus {
  border-color: #ffdd33; /* Brighter gold on focus */
  box-shadow: 0 0 15px rgba(255, 221, 51, 0.8); /* Stronger gold shadow effect */
}

.category-section-accept {
  margin-top: 15px;
  /* border: 1px solid #ffdd33; */
  box-shadow: gold 0 0 2px 2px;
  margin: 20px 0;
}

.active-category {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 90%;
}

.place-category-cnt {
  display: flex;
  align-items: start;
  justify-content: start;
  margin-top: 10px;
  flex-direction: column;
}

.category-edit {
  background-color: rgb(114, 114, 114);
  width: 260px;
  padding: 0 10px;
  border-radius: 15px;
  margin: 10px 0;
  font-weight: 700;
  color: wheat;
  display: flex;
  align-items: center;
}

.arrows-category {
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  font-weight: 700;
}

.arrow-btn {
  background-color: wheat;
  color: white;
  width: 22px;
  height: 22px;
  margin: 10px 0;
  border-radius: 20px;
}
