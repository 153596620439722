/* TableModal.css */
.hostess-table-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Dark semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.hostess-table-modal {
  background-color: #333; /* Dark background for the modal */
  color: wheat; /* Wheat-colored text */
  border-radius: 8px;
  padding: 20px;
  width: 330px;
  animation: fade-in 0.5s ease-in-out;
}

.hostess-table-modal h2 {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 15px;
}

.hostess-table-modal p {
  margin: 10px 0;
}

.hostess-table-modal select,
input,
.hostess-table-modal textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #444; /* Dark background for select/textarea */
  color: wheat;
  border: 1px solid #555;
  height: 30px;
}

.hostess-table-modal textarea {
  resize: vertical;
}

.hostess-table-modal button {
  background-color: #555;
  color: wheat;
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 15px;
}

.hostess-table-modal button:hover {
  background-color: #777; /* Lighter on hover */
}

@keyframes fade-in {
  from {
    opacity: 0;
    margin-top: 1500px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
