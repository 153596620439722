.setting-cnt {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
}
.setting-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px #919191;
  border-radius: 20px;
  align-items: center;
  justify-content: space-around;
  margin: 20px;
  padding: 15px;
}

.menu-example-cnt {
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  margin-bottom: 20px;
}

.menu-example1 {
  background-color: aqua;
  height: 150px;
  width: 250px;
}

.menu-example2 {
  background-color: rgb(176, 112, 0);
  height: 150px;
  width: 250px;
}
.menu-example3 {
  background-color: rgb(233, 60, 192);
  height: 150px;
  width: 250px;
}

.input-category {
  border: none;
  background-color: transparent;
}

.settings-button-cnt {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.settings-btn {
  margin-bottom: 15px;
  height: 50px;
  margin: 10px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-wrap: wrap;
}

.new-menu-ctn {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

.new-menu-category {
  width: 200px;
  border: none;
  font-size: 20px;
}

.new-menu1 {
  display: flex;
  flex-direction: column;
  box-shadow: gray 0 0 10px 2px;
  margin: 15px 20px;
  padding: 15px;
}

.delete-meal-svg {
  color: red;
  font-size: 20px;
}

.eye-meal-svg {
  color: rgb(57, 57, 57);
  font-size: 20px;
}

.delete-meal-btn {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: 30px;
  align-self: end;
  margin-left: 5px;
}

.settings-btn-cnt {
  display: flex; /* Use flexbox to make buttons appear in one line */
  gap: 10px; /* Set the margin between buttons */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent buttons from wrapping to the next line */
  padding: 10px 10px;
  margin-bottom: 20px;
  overflow: auto;
}

.settings-btn-cnt button {
  flex: 0 0 120px; /* Set a fixed width for each button */
  background-color: #2e2e2e;
  text-wrap: pretty;
}
