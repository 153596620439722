.members-table {
  width: 100%;
  border-collapse: collapse;
}

.members-table th,
.members-table td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  font-size: 12px;
}

.members-table th {
  background-color: #f2f2f2;
  color: #333;
}

.members-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.members-table tbody tr:hover {
  background-color: #faf5e5;
}
