.menus-example-cnt {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: wheat;
}

.menus-example-cnt h2 {
  margin-top: 30px;
}

.card-example-cnt {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 30px;
}

.card-example {
  height: 130px;
  width: 130px;
  box-shadow: 0 0 15px rgb(72, 72, 72);
  border-radius: 40px;
  display: flex;
  align-items: center;
  margin: 20px 0;
  background-color: none;
  box-shadow: wheat 0 0 10px 0px;
}
