.menu2-cnt {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.menu2-category {
  color: white;
  text-shadow: 0 0 4px black;
  font-size: 45px;
  margin: 0;
  margin-bottom: 15px;
  text-align: center;
  align-self: center;
}

.menu2-prices-cnt {
  display: flex;
  justify-content: flex-end;
}

.menu2-prices-title {
  color: white;
  font-size: 11px;
  width: 40px;
  text-align: center;
  font-weight: bold;
}

.menu2-items-cnt {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.menu2-item {
  color: white;
  font-size: 19px;
  font-weight: bold;
}

.menu2-price {
  color: white;
  font-size: 19px;
  width: 40px;
  text-align: center;
  font-weight: bold;
}
