@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.home-button {
  width: 100px;
  height: 100px;
  background: linear-gradient(
    135deg,
    #856d3d,
    #d4af37,
    #f0dd8f,
    #ae8634,
    #b6a45e
  );
  color: black;
  border-radius: 100%;
  /* animation: gradientAnimation 5s ease infinite; */
  /* background-size: 150% 150%; */
}
