.login-tab {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 25px;
  color: white;
}

.login-cnt {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error {
  background: wheat;
  color: red;
  font-weight: bold;
  padding: 10px;
  margin: 15px;
}
