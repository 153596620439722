/* accessibility.css */

.acc-btn {
  background-color: #0041ff;
  position: fixed;
  z-index: 10;
  left: 0;
  height: 40px;
  width: 40px;
  top: 555px;
  padding: 0;
  transition: left 0.5s ease;
}

.acc-cnt {
  background-color: wheat;
  position: fixed;
  z-index: 10;
  left: -240px;
  height: 350px;
  width: 240px;
  top: 205px;
  display: flex;
  padding: 0;
  flex-direction: column;
  transition: left 0.5s ease;
}

.acc-cnt.open {
  left: 0;
}

.acc-cnt button {
  cursor: pointer;
  background-color: transparent;
  font-weight: 600;
  height: 39px;
  border: none;
  border-bottom: black solid 1px;
  box-shadow: none;
  color: black;
  border-radius: 0;
}
