.waiter-table-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  z-index: 1000;
  overflow: auto;
  padding: 50px 0;
}

.waiter-accept-guests {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.waiter-accept-guests button {
  margin: 0;
}

.waiter-accept-guests p {
  width: 650px;
  margin-left: 30px;
}

.hostess-table-modal {
  background-color: #333; /* Dark background for the modal */
  color: wheat; /* Wheat-colored text */
  border-radius: 8px;
  padding: 20px;
  width: 330px;
  animation: fade-in 0.5s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
    margin-top: 1500px;
  }
  to {
    opacity: 1;
    margin-top: 400;
  }
}

.waiter-guests-cnt {
  display: flex;
  overflow-x: auto;
}

.waiter-guests {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 auto;
  margin: 10px;
}

.waiter-modal-btn-cnt {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 15px;
}

.waiter-modal-btn-cnt button {
  background-color: transparent;
  border-bottom: wheat solid 1px;
  width: auto;
}

.waiter-modal-allergies {
  background-color: red;
  color: black;
  font-weight: bold;
  padding: 0 5px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}
