.category {
  color: white;
  text-shadow: 0 0 15px white;
  font-size: 45px;
  margin-bottom: 5px;
  align-self: center;
}

.menu-btn-cnt {
  display: flex; /* Use flexbox to make buttons appear in one line */
  gap: 20px; /* Set the margin between buttons */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent buttons from wrapping to the next line */
  padding: 10px 20px;
  margin-bottom: 20px;
  overflow: auto;
}

.menu-btn-cnt::-webkit-scrollbar {
  width: 8px;
}

.menu-btn-cnt::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.menu-btn-cnt::-webkit-scrollbar-track {
  background-color: transparent;
}

.menu-btn-cnt button {
  width: 120px;
  height: 50px;
  background: linear-gradient(
    135deg,
    #856d3d,
    #d4af37,
    #f0dd8f,
    #ae8634,
    #b6a45e
  );
  color: black;
  font-weight: 700;
  flex: 0 0 auto;
  margin: 0 10px;
  text-wrap: wrap;
}

.members {
  min-height: 300px;
  margin-top: 15px;
  text-align: center;
  width: 300px;
  background-color: wheat;
  padding: 10px;
  box-shadow: #c7b188 0 0 10px 5px;
}

.members form {
  height: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #2e2e2e;
  align-items: center;
}

.members button {
  transition: 0.8s;
  width: 90%;
}

.members button:disabled {
  transition: 0.8s;
  background-color: #cccccc; /* Grey */
  cursor: not-allowed;
}
