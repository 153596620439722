.home-footer-cnt {
  display: flex;
  width: 100%;
  border-top: 1px solid gray;
  align-items: center;
  flex-direction: column;
  height: 100px;
  padding-top: 10px;
  margin-top: 25px;
  background: #00000075;
  border-radius: 40px 40px 0 0;
  box-shadow: wheat 0 0 16px 4px;
}

.home-footer-logo {
  height: 60px;
  width: 60px;
  position: absolute;
  object-fit: fill;
  margin-top: 13px;
  left: 10px;
  z-index: 0;
}

.home-footer-details {
  display: flex;
  font-size: 16px;
  margin-top: 10px;
  color: wheat;
  font-weight: 700;
}

.home-footer-details span {
  margin: 0 5px; /* Added margin between the spans */
}
