/* Main container */
.table-manager-container {
  padding: 20px;
  background-color: #1f1f1f; /* Dark background */
  color: #f5deb3; /* Wheat color text */
  max-width: 1200px;
  margin: auto;
  border-radius: 10px;
  width: 90%;
}

/* Title */
.table-manager-title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 30px;
  color: #ffcc00; /* Gold color for the title */
}

.table-main-btn {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 15px;
}
/* Table Canvas Container using Flexbox */
.table-canvas-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; /* Distribute tables evenly */
  gap: 20px;
  padding: 10px;
}

/* Table Item Style */
.table-canvas-item {
  background-color: #444; /* Darker background for tables */
  padding: 20px;
  width: 180px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-canvas-item.active {
  background-color: #555; /* Highlight active table */
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.4);
  height: 170px;
}

/* Table number and capacity */
.table-number {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}

.table-capacity {
  font-size: 16px;
  color: #b5b5b5;
  margin-top: 10px;
}

/* Action buttons */
.table-actions {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 7px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}

.table-action-btn {
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.table-action-btn:hover {
  color: #ffcc00; /* Highlight color on hover */
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .table-canvas-container {
    justify-content: center; /* Center tables on smaller screens */
  }

  .table-canvas-item {
    width: 150px; /* Adjust table width on smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .table-canvas-container {
    justify-content: center; /* Single column for very small screens */
  }

  .table-canvas-item {
    width: 120px; /* Smaller width for small mobile screens */
  }
}

/* Main container */
.table-manager-container {
  padding: 20px;
  background-color: #1f1f1f;
  color: #f5deb3;
  max-width: 1200px;
  margin: auto;
  border-radius: 10px;
}

/* Title */
.table-manager-title {
  text-align: center;
  font-size: 28px;
  margin-bottom: 30px;
  color: wheat;
}

/* Table Canvas Container */
.table-canvas-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  padding: 10px;
}

/* Table Item Style */
.table-canvas-item {
  background-color: #444;
  padding: 20px;
  width: 130px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-canvas-item.active {
  background-color: #555;
  transform: scale(1.05);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.4);
}

/* Table info */
.table-number {
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}

.table-capacity {
  color: #373737;
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
}

/* Editable Input */
.table-edit-input {
  width: 70%;
  padding: 5px;
  margin-top: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #333;
  color: #f5deb3;
  text-align: center;
  font-size: 16px;
}

/* Save Button */
.table-save-btn {
  background-color: #ffcc00;
  color: #1f1f1f;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.table-save-btn:hover {
  background-color: #e6b800;
}

/* Action buttons */
.table-actions {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
}

.table-action-btn {
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.table-action-btn:hover {
  color: #ffcc00;
}

.table-manager-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.table-canvas-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.table-canvas-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  width: 120px;
  text-align: center;
  height: 100px;
}

.table-actions {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.table-action-btn {
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  padding: 25px 10px;
  align-items: center;
}

.modal-table-content {
  background-color: #545454;
  padding: 1.5rem;
  border-radius: 8px;
  width: 95%;
  max-width: 500px;
  animation: slideUp 0.5s forwards;
  height: fit-content;
  max-height: 95%;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.orders-table th,
.orders-table td {
  padding: 0.5rem;
  text-align: center;
  border: 1px solid #ddd;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.total-sum {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.modal-edit-table {
  background-color: #545454;
  padding: 1.5rem;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  animation: slideUp 0.5s forwards;
  display: flex;
  flex-direction: column;
}

.table-edit-fields {
  display: flex;
  flex-direction: column;
}

.table-edit-field {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  font-weight: bold;
  align-items: center;
}

.table-edit-field input,
textarea,
select {
  width: 100px;
  border-radius: 15px;
  background-color: black; /* Set background color to black */
  color: wheat; /* Set text color to wheat */
  border: 1px solid #ccc; /* Optional: set border color */
  padding: 8px;
  border: none;
}

.table-edit-field-select {
  border-radius: 15px;
  background-color: black; /* Set background color to black */
  color: wheat; /* Set text color to wheat */
  padding: 8px;
  border: none;
  height: 40px;
  width: auto;
}

.table-edit-field textarea {
  width: 190px; /* Adjust the width for textarea */
}

.table-edit-field input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(
    0.8
  ); /* Invert the icon color to make it visible on black background */
}

.submit-table-edit {
  padding: 10px;
  box-shadow: none;
  border-radius: 15px;
  background-color: black; /* Set background color to black */
  color: wheat; /* Set text color to wheat */
  border: 1px solid #ccc; /* Optional: set border color */
  padding: 8px;
  border: none;
}

.table-status-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  width: 85%;
}

.table-status {
  display: flex;
  align-items: center;
}

.status-box {
  width: 20px;
  height: 20px;
  margin-left: 3px;
  border-radius: 3px;
}

.available {
  background-color: #00e50a; /* צבע ירוק לפנוי */
}

.occupied {
  background-color: #ff2b1b; /* צבע אדום לתפוס */
}

.reserved {
  background-color: #ffd700; /* צבע צהוב לשמור */
}
