/* בסיסי */

.settings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 25px;
}

.settings-header h1 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 40px;
}

.settings-sections {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 90%;
}

.settings-section {
  background-color: #505050;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

.settings-section h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.settings-section p {
  font-size: 16px;
  color: wheat;

  margin-bottom: 20px;
}

.update-button {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.update-button:hover {
  background-color: #45a049;
}

/* Logout Button */
.logout-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.logout-button {
  background-color: #f44336;
  color: white;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #e53935;
}
