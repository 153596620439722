.menu4-cnt {
  display: flex;
  flex-direction: column;
}

.menu4-category {
  color: white;
  text-shadow: 0 0 4px black;
  font-size: 45px;
  margin: 0;
  text-align: center;
  align-self: center;
}

.menu4-titles {
  color: white;
  text-align: center;
  font-size: 17px;
}

.menu4-item-name {
  color: white;
  margin-bottom: 15px;
}

.menu4-img {
  position: absolute;
  width: 100px;
  left: 40px;
  opacity: 55%;
  margin-top: 24px;
}
